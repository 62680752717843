import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import Image from "../image";
import { viewports, colors } from "../../style-vars";

const { Title } = Typography;
const { mdOrSmaller } = viewports;

const PartnerLogos = ({ style, title }) => {
  return (
    <Row
      className="logos-wrapper"
      align="middle"
      justify="center"
      // gutter={20}
      style={style || { margin: "70px 20px 70px 20px" }}
    >
      {title.length ? (
        <Col xs={24}>
          <Title className="partners__title" level={4}>
            {title}
          </Title>
        </Col>
      ) : null}
      <Col xs={6} md={2}>
        <Image imageName="dodger-stadium.png" className="local-logos-large" />
      </Col>
      <Col xs={6} md={2}>
        <Image imageName="target-field.png" className="local-logos-large" />
      </Col>
      <Col xs={6} md={2}>
        <Image imageName="yankee-stadium.png" className="local-logos-large" />
      </Col>
      <Col xs={6} md={2}>
        <Image imageName="bbt-ballpark.png" className="local-logos" />
      </Col>
      <Col xs={6} md={2}>
        <Image imageName="globe-life-field.png" className="local-logos-large" />
      </Col>
      <Col xs={6} md={2}>
        <Image imageName="shalen-field.png" className="local-logos-large" />
      </Col>

      <style jsx global>{`
        .logos-wrapper {
          padding: 0 20px;
        }

        .local-logos {
          width: auto;
        }

        .local-logos-large {
          width: auto;
        }

        h4.ant-typography.partners__title {
          color: ${colors.black} !important;
          text-align: center;
          margin: 40px 0;
        }

        @media ${mdOrSmaller} {
          .local-logos {
            width: 100px;
          }

          .local-logos-large {
            margin-top: 20px;
          }
        }
      `}</style>
    </Row>
  );
};

PartnerLogos.propTypes = {
  style: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
};
PartnerLogos.defaultProps = {
  style: {},
  title: "",
};

export default PartnerLogos;
